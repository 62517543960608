var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('div',{staticClass:"mb-5 pa-5"},[_c('div',{staticClass:"row gutters justify-space-between title",attrs:{"primary-title":""}},[_c('div',{staticClass:"col flex-grow-0"},[_c('v-btn',{attrs:{"outlined":"","large":"","color":"primary"},on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},[_c('v-icon',[_vm._v("filter_list")]),_vm._v("Filtrer ")],1)],1)]),_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter),expression:"showFilter"}]},[_c('v-row',{staticClass:"xs14"},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate.immediate",value:('integer'),expression:"'integer'",modifiers:{"immediate":true}}],attrs:{"clearable":"","label":"Lot","data-vv-name":"lot","error-messages":_vm.errors.collect('lot')},model:{value:(_vm.lotFilter),callback:function ($$v) {_vm.lotFilter=$$v},expression:"lotFilter"}})],1)],1)],1)]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"light":"","headers":_vm.lotTableHeaders,"items":_vm.items,"item-key":"id","show-select":"","loading":_vm.loading,"loading-text":"Chargement en cours","no-data-text":"Aucun lot à valider","sort-by":"id","items-per-page":50,"footer-props":{
          itemsPerPageText: 'Lots par page:',
          itemsPerPageOptions: [20, 50, 100, 200],
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Aucun lot à valider ")]},proxy:true},{key:"header.data-table-select",fn:function(){return [_c('v-checkbox',{attrs:{"input-value":_vm.selectAllValue,"indeterminate":_vm.selectAllIndeterminate},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAll($event)}}})]},proxy:true},{key:"item.montant_fcpe",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.montant_fcpe))+" ")]}},{key:"item.montant_total",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.montant_total))+" ")])]}},{key:"item.date_creation",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_creation))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"blue"},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}],null,false,63074868)}):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }