<template>
  <div>
    <v-card>
      <div class="mb-5 pa-5">
        <div
          primary-title
          class="row gutters justify-space-between title"
        >
          <div class="col flex-grow-0">
            <v-btn
              outlined
              large
              color="primary"
              @click="showFilter = !showFilter"
            >
              <v-icon>filter_list</v-icon>Filtrer
            </v-btn>
          </div>
        </div>

        <v-slide-y-transition>
          <div v-show="showFilter">
            <v-row class="xs14">
              <v-col
                cols="4"
                class="text-right"
              >
                <v-text-field
                  v-model="lotFilter"
                  v-validate.immediate="'integer'"
                  clearable
                  label="Lot"
                  data-vv-name="lot"
                  :error-messages="errors.collect('lot')"
                />
              </v-col>
            </v-row>
          </div>
        </v-slide-y-transition>

        <v-data-table
          v-model="selected"
          light
          :headers="lotTableHeaders"
          :items="items"
          item-key="id"
          show-select
          class="elevation-3"
          :loading="loading"
          loading-text="Chargement en cours"
          no-data-text="Aucun lot à valider"
          sort-by="id"
          :items-per-page="50"
          :footer-props="{
            itemsPerPageText: 'Lots par page:',
            itemsPerPageOptions: [20, 50, 100, 200],
          }"
        >
          <v-progress-linear
            v-if="loading"
            v-slot:progress
            color="blue"
          />

          <template v-slot:no-data>
            Aucun lot à valider
          </template>

          <template v-slot:header.data-table-select>
            <v-checkbox
              :input-value="selectAllValue"
              :indeterminate="selectAllIndeterminate"
              @click.stop="toggleAll"
            />
          </template>

          <template v-slot:item.montant_fcpe="{ item }">
            {{ item.montant_fcpe | currency }}
          </template>

          <template v-slot:item.montant_total="{ item }">
            <div class="text-right">
              {{ item.montant_total | currency }}
            </div>
          </template>

          <template v-slot:item.date_creation="{ item }">
            {{ item.date_creation | formatDate }}
          </template>
        </v-data-table>

        <v-progress-linear
          v-if="loading"
          :indeterminate="true"
        />
      </div>
    </v-card>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import selectAllMixin from '../../mixins/vTableDataSelectAll';

export default {
  name: 'ListValidationsNationalesComponent',
  mixins: [selectAllMixin],
  props: {
    // v-model is equivalent to 
    // :value="x"
    // @input="val => x = val"
    // so to use v-model on a custom props we have to $emit 'input'
    // and have the props 'value'
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      showFilter: false,
      lotFilter: '',
      selected: [],
      lotTableHeaders: [
        {
          text: 'CDPE',
          sortable: true,
          value: 'perimetre_code',
        },
        {
          text: 'Lot',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Nbre adh',
          sortable: true,
          value: 'nombre_adhesions',
        },
        {
          text: 'Abo adh',
          sortable: true,
          value: 'nombre_rp_service',
        },
        {
          text: 'Abo non adh',
          sortable: true,
          value: 'nombre_rp_direct',
        },
        {
          text: 'Mt à payer à la FCPE',
          sortable: true,
          value: 'montant_fcpe',
        },
        {
          text: 'Mt total lot',
          sortable: true,
          value: 'montant_total',
        },
        {
          text: 'Date de la validation',
          sortable: true,
          value: 'date_creation',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('validationsNationales', {
      loading: 'getLoading',
      lots: 'getLots',
    }),
    items() {
      return this.lots.filter(el => el.id.toString().startsWith(this.lotFilter));
    },
  },
  watch: {
    value(val) {
      this.selected = val;
    },
    selected(val) {
      // v-model is equivalent to 
      // :value="x"
      // @input="val => x = val"
      // so to use v-model on a custom props we have to $emit 'input'
      // and have the props 'value'
      this.$emit('input', val);
    },
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions('validationsNationales', {
      load: 'loadLotsValidationsNationales',
    }),
  },
};
</script>